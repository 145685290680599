.map-container{
    width: 100%;
    height: 100vh;
    flex-grow: 1;
    min-height: 50%;
}

.Root {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.TripInformation {
    padding: 20px;
}

.TripInformation__title {
    margin-bottom: 20px;
    text-align: center;
}

.TripInformation__info {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #FFFFFF20;
    padding: 20px;
    border-radius: 10px;
}

.TripInformation__info .TripInformation__description {
    margin-top: 5px;
}

.DocumentPage__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    gap: 10px;
  }
  
.DocumentPage__title, .DocumentPage__subtitle {
    text-align: center;
}
  
.DocumentPage__download-btn {
    padding: 20px;
    background-color: #4EABE9;
    color: #FFF;
    border-radius: 10px;
    font-weight: bold;
    margin-top: 20px;
}

.NotAvailable {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    text-align: center;
}

.Loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    text-align: center;
}
